import React from "react"

import Layout from "../components/layout"

const About = () => (
  <Layout>
    <div>
      <h1>hello </h1>

      <p>
        McSweeney's chia gentrify leggings put a bird on it you probably haven't
        heard of them single-origin coffee chambray Neutra lo-fi 90's American
        Apparel roof party banjo fap tousled Bushwick yr butcher lomo keytar
        vinyl tote bag twee banh mi flannel fanny pack PBR ennui mixtape
        cornhole small batch gastropub locavore squid cray deep v ethical Tumblr
        Williamsburg cliche semiotics jean shorts pop-up street art biodiesel
        Portland mustache Wes Anderson post-ironic keffiyeh disrupt Shoreditch
        umami drinking vinegar Truffaut blog Echo Park iPhone Austin artisan
        trust fund cardigan bitters Intelligentsia vegan tofu narwhal church-key
        synth plaid quinoa seitan crucifix Schlitz scenester direct trade swag
        pork belly aesthetic distillery whatever selfies pour-over Helvetica +1
        letterpress bespoke forage Marfa Godard irony messenger bag brunch craft
        beer kale chips readymade slow-carb paleo shabby chic photo booth four
        loko mlkshk mumblecore Vice fixie gluten-free Etsy Pitchfork normcore
        asymmetrical authentic kogi pickled skateboard pug cred polaroid
        fingerstache occupy raw denim flexitarian retro sustainable heirloom
        selvage wayfarers sartorial salvia art party next level Tonx XOXO
        Thundercats DIY ugh organic stumptown farm-to-table meggings Carles wolf
        master cleanse beard meh hoodie Blue Bottle typewriter fashion axe
        tattooed freegan 8-bit sriracha 3 wolf moon hashtag literally hella VHS
        Cosby sweater try-hard High Life food truck Pinterest Banksy Odd Future
        PBR&B YOLO bicycle rights Kickstarter dreamcatcher actually before they
        sold out kitsch Brooklyn viral chillwave
      </p>
    </div>
  </Layout>
)

export default About
